<template>
    <div id="suivi-de-connexion">
        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :withFilters="true" :filterComponent="AgGridDateFilter" @filterClicked="filterClicked"
                    :agGrid="agGrid" pageTitle="Suivi de connexion" @refresh="loadData" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import AgGridDateFilter from "../components/ag-grid/AgGridDateFilter.vue";

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            AgGridDateFilter: AgGridDateFilter,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Utilisateur",
                        field: "name",
                        width: 180,
                    },
                    {
                        headerName: "Date de connexion",
                        field: "loginDate",
                        valueGetter(tr) {
                            if (tr.data.loginDate != null)
                                return tr.data.loginDate.substring(0, 10) + " | " + tr.data.loginDate.substring(11, 16);
                            else
                                return ''
                        },
                        width: 100
                    },
                    {
                        headerName: "Date de déconnexion",
                        field: "logoutDate",
                        valueGetter(tr) {
                            if (tr.data.logoutDate != null)
                                return tr.data.logoutDate.substring(0, 10) + " | " + tr.data.logoutDate.substring(11, 16);
                            else
                                return ''
                        },
                        width: 100
                    },
                ],
                rows: []
            },
            showLoading: true,
            dateDebut: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
            dateFin: new Date().toISOString().split('T')[0],
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        filterClicked(obj) {
            this.startDate = obj.sDate;
            this.endDate = obj.eDate;
            this.loadData();
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("/usertrack" + "?dateDebut= " + this.dateDebut + "&dateFin=" + this.dateFin);
            this.agGrid.rows = response.data.reverse();
            this.showLoading = false;
        },
    }
}
</script>

<style lang="scss"></style>
